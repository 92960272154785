.container {
    max-width: 250px;
    border-right: 1px solid rgba(219, 219, 219, 0.223);
}

.nav {
    padding: 0px 8px 12px 16px;
    border-bottom: 1px solid rgba(219, 219, 219, 0.333);
}

.title {
    margin: 0;
    padding: 14px 0px;
    font-size: 0.8rem;
    font-weight: 500;
}

.nav__list {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.nav__link {
    text-decoration: none;
    font-size: 0.9rem;
    color: rgb(36, 36, 36);
    display: block;
    font-weight: 400;
    line-height: 20px;
    padding-bottom: 8px;
}

.list_products_item {
    display: flex;
    margin-bottom: 8px;
}

.list_products__name {
    font-size: 0.9rem;
    margin-left: 5px;
}

.list_products__btn {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 20px;
    margin-top: 14px;
    color: rgb(13, 92, 182);
    cursor: pointer;
}

.price p {
    cursor: pointer;
    margin: 0;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 60px;
    font-size: 0.9rem;
    background: rgb(238, 238, 238);
    margin-bottom: 5px;
}

.price label {
    display: block;
    font-size: 0.9rem;
    opacity: 0.8;
    margin-bottom: 3px;
}

.gourp {
    display: flex;
    margin-bottom: 5px;
    align-items: center;
}

.gourp input {
    width: 50%;
    outline: none;
    height: 25px;
    line-height: 25px;
    padding: 0 5px;
    border-radius: 5px;
    border: 1px solid rgba(155, 155, 155, 0.522);
}

.seperate {
    position: relative;
    top: -2px;
    margin: 0 5px;
}

.btn {
    background: none;
    outline: none;
    padding: 5px 20px;
    border: 1px solid rgba(4, 155, 242, 0.777);
    color: rgb(28, 109, 250);
    font-size: 0.8rem;
    border-radius: 5px;
}

@media  only screen and (max-width:769px){
 
}