.slider{
    width: 95vw;
    height: 30vw;
    margin: 0 auto;
    margin-top: 10px;
}
.slider{
    display: none;
    visibility: hidden;
}
@media screen and (max-width: 768px){
    .slider{
        display: block;
        visibility: visible;
    }
}
