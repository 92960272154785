.slide{
    width: 490px;
    height: 275px;
    border-radius: 5px;
    overflow: hidden;
}
@media screen and (max-width: 768px){
    .slide{
        width: 100vw;
        height: calc((100vw*(275/490)));
        padding: 0 2vw;
        margin-top: -5px;
        margin-bottom: 10px;
    }
}