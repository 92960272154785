.option{
    padding: 15px;
    background: #fff;
    margin-top: 15px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.input_option{
    outline: none;
    border: solid 1px #ccc;
    padding: 6px 12px;
    min-width: 350px;
    border-radius: 8px;
}