.ProfileList {
  width: 400px;
  min-height: 350px;
  padding: 16px;
  background-color: rgb(255, 255, 255);
}
.profileListManuLeft{
  font-size: 14px;
    line-height: 20px;
    margin: 0px 0px 0px 6px;
    color: rgb(56, 56, 61);
}
.profileListManuLeftlisticon{
  display: flex;
  padding: 12px 0;
}
.profileListManuLeftlisticon img{
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
.profileListManuLeftimg{
  flex: 1;
  display: flex ;
}
.profileListManuLeftlisticon button{
  display: inline-block;
  height: 28px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  padding: 0px 12px;
  color: rgb(11, 116, 229);
  border: 1px solid rgb(11, 116, 229);
  background-color: rgb(255, 255, 255);
}
.profileListManuLeft h2{
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: rgb(100, 100, 109);
}
.ProfileListHeader{
    font-size: 20px;
    line-height: 32px;
    font-weight: 300;
    margin: 4px 0px 12px;
    padding-top: 50px;
}