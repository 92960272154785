.HotItem{
    margin: 15px 0 ;
    background: #fff;
    border-radius: 5px;
    padding: 10px 20px;
}
.HotItem h5{
    font-size: 20px;
    /* line-height: 28px; */
    text-transform: capitalize;
    font-weight: initial;
    margin: 10px 0 20px 0;
    padding: 0;
}
.ListItem{
    display: grid;
    grid-template-columns: repeat(10 , 1fr);
    grid-template-rows: repeat(2, 1fr); 
}
.ListItem_item{
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    flex-shrink: 0;
}
.ListItem_item img{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 10px;
}
.ListItem_item h4{
    padding: 0 10px;
    font-size: 13px;
    font-weight: 400;
}
@media screen and (max-width: 768px){
    .HotItem{
        margin: 0;
        border-radius: 0;
        padding: 0;
        border-bottom: 6px solid #d6d6d6;
    }
    .HotItem h5{
        padding: 10px 20px;
        margin: 0;
        font-size: 16px;
        color: #000;
    }
    .ListItem{
        max-width: 100vw;
        overflow-x: auto;
        
    }
    .ListItem::-webkit-scrollbar{
        display: none;
    }
    .ListItem_item{
        width: 25vw;
    }
    .ListItem_item img{
        height: 40px;
        width: 40px;
    }
}