.AddItem{
    width: 1240px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0 0 50px 0;
}
.AddItem_left{
    width: 980px;
    flex-shrink: 0;
}

.AddItem_right{
    width: 240px;
    position: fixed;
    top: 20px;
    right: calc(((100vw - 1240px)/2));
    margin-right: -20px;
}
