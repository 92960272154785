.Footer {
  display: flex;
  justify-content: space-between;
  max-width: 1240px;
  margin: 0 auto;
  border-bottom: 5px;
}
.Footer-subFooter {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.Footer-subFooter h4 {
  margin: 0 0 12px 0;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgb(56, 56, 61);
  margin-bottom: 12px;
  margin-top: 0px;
}
.Footer-FooterReDetal h4 {
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: rgb(56, 56, 61);
  margin-bottom: 12px;
  margin-top: 20px;
}
.Footer-listIcon {
  width: 200px;
}
.Footer-listIcon Icon {
  margin-right: 30px;
}
.Footer-subFooter span,
.icon {
  display: inline-block;
  margin-right: 15px;
  width: 32px;
}
.Footer-subFooter,
.Footer-subFooter p {
  margin: 0;
  padding: 0;
  line-height: 1.5;
}
.Footer-subFooter {
  font-size: 12px;
}
.Footer-subFooter a span {
  font-size: 12px;
  font-weight: bolder;
  text-decoration: none;
  color: black;
  width: max-content;
}
.Footer-subFooter a {
  text-decoration: none;
  color: rgb(128, 128, 137);
  font-size: 12px;
  font-weight: 400;
}
.Footer-FooterDetal-p {
  color: rgb(128, 128, 137);
}
.Footer-subFooter span {
  width: max-content;
  margin-bottom: 8px;
  color: rgb(128, 128, 137);
  font-size: 12px;
  font-weight: 400;
  margin-right: 2px;
}
.list-icon {
  display: flex;
  flex-direction: column;
  float: right;
  /* margin-right: 38px; */
}
.list-icon img {
  margin-bottom: 8px;
}
.Footer-FooterLink {
  text-decoration: none;
  font-size: 12px;
  line-height: 16px;
  font-weight: normal;
  color: rgb(128, 128, 137);
  display: inline-block;
  padding: 4px 0;
}
.Footer-FooterLink:hover {
  text-decoration: underline;
}
.Footer-FooterReDetal-icon {
  width: 206px;
}
@media screen and (max-width: 768px){
  .Footer{
    display: none;
    visibility: hidden;
  }
}