.container {
    max-width: calc(100% - 250px);
    
}

.title {
    margin: 0;
    padding: 16px 0px 0px 16px;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 30px;
}

.slider {
    width: 100%;
    margin-top: 10px;
    height: 250px;
}

.select {
    display: flex;
    padding: 16px 0px 0px;
}

.img {
    max-width: 100%;
    height: 100%;
    object-fit: fill;
}

.select_name {
    margin: 0px 16px;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s;
}

.select_name:hover {
    border-bottom: 4px solid blue;
}

.select_active {
    color: blue;
    font-weight: 500;
    border-bottom: 4px solid blue;
}

.products {
    display: grid;
    gap: 20px 0;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 30px;
}

.products_item {
    cursor: pointer;
    overflow: hidden;
    padding: 12px 16px;
}


.products_item:hover {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
}

.products_thumbnail {
  position: relative;
    margin: 0px 12px 12px;
    text-align: center;
}

.thumbnail_img {
    position: absolute;
    top: 0;
    left: 0px;
    height: 20px;
}

.ad {
    display: inline-block;
    font-size: 0.6rem;
    padding: 0 5px;
    background-color: beige;
}

.products_title {
    font-size: 0.9rem;
    line-height: 20px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin: 0;
}

.products_rating {
    display: flex;
    align-items: center;
}

.products_sold {
    font-size: 0.8rem;
}

.products_sold {
    position: relative;
    padding-left: 15px;
}

.products_sold::after {
    position: absolute;
    content: '';
    top: 55%;
    left: 7px;
    transform: translateY(-50%);
    width: 1px;
    height: 10px;
    background-color: rgba(157, 157, 157, 0.596);
}

.products_price_box {
    display: flex;
    align-items: center;
}

.products_price {
    color: red;
    font-weight: 600;
    font-size: 1rem;
}

.products_discount {
    color: red;
    margin-left: 10px;
    display: inline-block;
    padding: 0 3px;
    line-height: 15px;
    font-size: 0.7rem;
    background-color: rgba(250, 175, 175, 0.185);
    border-radius: 3px;
    border: 1px solid red;
}

.badge_under {
    height: 18px;
}

.ant-pagination-item-2{
    border-radius: 50% !important;
}
.slick-dots{
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;

}

@media  only screen and (max-width:769px){
    .container {
      max-width: 100%;
    }
    .products {
        display: grid;
        gap: 20px 0;
        grid-template-columns: repeat(2, 1fr);
    }

    .image_img {
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .slider {
        height: 150px;
    }

    .swiper-slide {
        height: 100%;
    }

    .select {
        width: 100%;
        overflow-x: auto ;
    }

    .select::-webkit-scrollbar {
        display: none;
    }

    .title {
        font-size: 1.2rem;
        padding: 8px 0px 0px 16px;
    }

    .select_name {
        display: block;
        flex-shrink: 0;
    }

    .products_item {
        padding: 12px 10px;
    }
    .products {
        padding:  0 5px;
    }
    .Pagination {
       float: unset !important;
      display: flex;
      justify-content: center;
    }
  }