*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.category{
    padding: 20px 250px;
    height: 100vh;
}
.listCategory{
    width: 612px;
    height: 60px;
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    box-shadow: 1px 2px 2px rgba(53, 52, 52, 0.5);
    margin-left: 250px;
    border-radius: 4px;
}
.imageName{
    display: flex;
    align-items: center;
}
.imageName p{
    margin-bottom: 0;
}
.img{
    width: 50px;
    height: 40px;
    margin: 0 8px;
    border: 1px solid cornflowerblue;
}
.button{
    margin-right: 10px;
    padding:5px 8px;
    border: none;
    border-radius: 4px;
}
.pagination{
    padding-top: 40px;
    padding-left: 250px;
}
.PageCategory{
    font-size: 20px;
    padding-left: 50px;
}
.modalInput{
    display: block;
    padding: 4px 12px;
    width: 90%;
    margin: 10px 0;
    border: 1px solid rgb(69, 71, 72);
    border-radius: 4px;
    outline: none;
}
.categorybutton{
    margin-right: 5px;
    padding: 4px 8px;
}