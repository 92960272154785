.sellUp.ant-switch-checked .ant-switch-handle{
    left: 28px;
}
.Add_Sku .ant-input-affix-wrapper{
    height: 36px;
    border-radius: 8px;
}
.Add_Sku {
    padding: 0 18px;
    
}
.Add_storege-btn{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 4px;
}
.Add_storege-btn .ant-input-number{
    width: 100%;
    height: 36px;
    border-radius: 8px;
}

.Add_storege-btn .ant-input-number .ant-input-number-handler-wrap{
    background: none;
}
.Add_special__price .ant-input-number .ant-input-number-handler-wrap{
    display: none;
    visibility: hidden  ;
}
.Add_special__price{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Add_special__price .ant-input-number{
    border: 0;
}
.Add_special__price .ant-input-number-focused .ant-input-number-handler{
    border:0;
    border-color: transparent;
    overflow: hidden;
}
.Add_price__varient{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
}
.Add_price__varient button{
    background: #f2f3f7;
    padding: 0;
    border:0;
    height: 36px;
    width: 100%;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    
}
.Add_Money{
    padding: 0 8px;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    color:rgb(136, 136, 136);
    flex-shrink: 0;
    border:1px solid rgb(216, 216, 216);
    border-right: 0;
    border-radius: 8px 0 0 8px;
}
.Add_price__input-varient{
    flex:1;
    height: 36px;
    border-radius: 0 8px 8px 0;

}
.Add_price__input-varient .ant-input-number-handler-wrap{
    background: none;
}
.Add_price__input-varient .ant-input-number-input,
.Input_special__price .ant-input-number-input,
.Input_add__storage .ant-input-number-input
{
    text-align: center;
}