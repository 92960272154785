.btn_price{
    font-size: 14px;
   border:0;
    height: 36px;
    padding: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    background: none;
}
.deno{
    padding: 0 8px;
    background: #f2f3f7;
    height: 100%;
    line-height: 36px;
    border-radius: 10px 0 0 10px;
    border: 1px solid #ccc;
    border-right: 0;
}
.Price_module{
    display: flex;
}
.Input_Price .ant-input-number-handler-wrap{
    background: none;
}
.sku{
    width: 130px;
    border-radius: 10px;
    border: #ccc solid 1px;
}
.submit_all{
    color:#fff;
    background-color: #1a71ff;
    border-radius: 10px;
    padding: 0 12px;
    height: 36px;
    line-height: 34px;
    font-size: 14px;
    border-width: 1px;
    border:0;
    cursor: pointer;
    margin-left: 12px;
}
.ListVarient{
    margin-top: 20px;
}
.setPrice>p{
    margin-bottom: 5px;
}