.container {
    display: flex;
    background-color: white;
}

@media  only screen and (max-width:769px){
    .container_box {
        display: none;
    }
}
