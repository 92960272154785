.detail_input{
    border-radius: 5px;
    outline: none;
    border:1px solid rgb(0, 110, 255);
    display: block;
    width: 100%;
    height: 35px;
    padding: 0 12px;
    margin-top: 4px;
    flex-shrink: 0;
}
.detail{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    gap: 20px;
}
.input_label{
    width: 48%;
    display: block;
}
.showmore{
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 12px;
}
.Brand{
    background: #fff;
    padding: 20px;
    margin-top: 15px;
    border-radius: 15px;
}