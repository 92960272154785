.Card{
    display: grid;
    width: 100%;
    /* overflow: hidden; */
    grid-template-columns: repeat(6,1fr);
}
.readMore{
    width: 240px;
    height: 36px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid rgb(11, 116, 229);
    background-color: transparent;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    text-align: center;
    color: rgb(11, 116, 229);
    cursor: pointer;
}
.readMore{
    width: 240px;
    height: 36px;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid rgb(11, 116, 229);
    background-color: transparent;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    text-align: center;
    color: rgb(11, 116, 229);
    cursor: pointer;
    position: relative;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
}
.readMore:hover{
    color: rgb(255, 255, 255);
    background-color: rgb(11, 116, 229);
}
@media screen and (max-width: 768px){
    .Card{
        overflow-x: hidden;
        grid-template-columns: 1fr 1fr;
        width: 100vw;
        position: relative;
        
    }
    .product{
        min-height: calc(100vh - 350px);
        background: #fff;
    }
    .readMore{
        position: absolute;
        top: calc(100% + 60px);
        left: 50vw;
        transform: translateX(-50%);
    }
    
}