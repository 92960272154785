#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-button-next:after, .swiper-button-prev:after{
  font-size: 30px;
  color: rgb(247, 247, 247);
}
.swiper-button-next,.swiper-button-prev{
  width: 30px;
  height: 56px;
  background: rgba(0, 0, 0,0.2);
}
.swiper-button-prev{
  left: 0px;
  top: calc(50% - 5px);
  border-radius: 0 5px 5px 0;
}
.swiper-button-next{
  right: 0;
  top: calc(50% - 5px);
  border-radius: 5px 0 0 5px;
}
@media screen and (max-width: 768px){
  .mySwiper6>.swiper-button-next,
  .mySwiper6>.swiper-button-prev{
    display: none;
  }
  .swiper.mySwiper6{
    border-radius: 10px;
    overflow: hidden;
  }
  .mySwiper6 .swiper-pagination-bullet{
    height: 2px;
    width: 10px;
    border-radius: unset !important;
    background: #fff;
    margin: 0 2px !important;
  }
  .mySwiper6 .swiper-pagination-bullets{
    bottom: 5px;
  }
}