.card{
    width: calc(1240px / 6);
    padding: 12px;
    background: #fff;
    color: black;
    position: relative;
    display: flex;
    flex-direction: column;
}
.card:hover{
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
    z-index: 1;
}
.card .img{
    width: 100%;
    flex-shrink: 0;
}
.card .content{
    flex: 1;
}
.card h3{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    font-size: 14px;
}
.rate span{
    margin-left: 4px;
    font-size: 13px;
}
.price{
    color: red;
}
.price span:first-child{
    font-weight: 600;
    font-size: 16px;
}
.price span:last-child{
    display: inline-block;
    padding: 0px 4px;
    border: 1px solid red;
    margin-left: 12px;
    height: 16px;
    line-height: 16px;
    font-size: 12px;
    border-radius: 2px;
}
.label{
    position: absolute;
    top: 10px;
    left: 10px;
    height: 18px;
    object-fit: cover;
    width: 74px !important;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content:space-between
}
.content h3{
    flex: 1;
}
@media screen and (max-width: 768px){
    .card{
        width: 50vw;
    }
}