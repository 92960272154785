
*, ::before, ::after {
    box-sizing: border-box;
}
div{
    display: block;
}


[type="checkbox"], [type="radio"] {
    box-sizing: border-box;
    padding: 0px;
}
button, input {
    overflow: visible;
}
body{
    overflow-x: hidden;
    margin: 0px;
}

.right{
    width: 100%;
}

h3 {
    display: block;
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

/* class right */
.elPTRG .right {
    flex: 1 1 0%;
}

element.style {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAu4AAADIAQMAAACNufaLAAAAA1BMVEX///+nxBvIAAAAKUlEQVR42u3BAQ0AAADCIPunNsc3YAAAAAAAAAAAAAAAAAAAAAAAACQcSjgAAcybzv4AAAAASUVORK5CYII=);
    background-size: cover;
    border-radius: 4px;
}
.cTGPxG.cTGPxG {
    border-radius: 4px;
    margin-bottom: 12px;
}

.cTGPxG .block-header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    margin-bottom: 12px;
}
.cTGPxG .block-header__title {
    color: rgb(128, 128, 137);
    font-weight: normal;
    margin: 0px;
}

.cTGPxG .customer_info {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(56, 56, 61);
    margin-bottom: 2px;
    font-weight: 600;
}
.cTGPxG .customer_info p {
    margin: 0px;
}
.cTGPxG .customer_info i {
    display: block;
    width: 1px;
    height: 20px;
    background-color: rgb(235, 235, 240);
    margin: 0px 8px;
}

.cTGPxG .address {
    color: rgb(128, 128, 137);
    font-weight: normal;
}
/* ////////////// */
.jMrZxT.jMrZxT {
    border-radius: 4px;
    margin-bottom: 12px;
}
.jMrZxT .block-header {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 13px;
    line-height: 20px;
    margin-bottom: 16px;
}
.jMrZxT .block-header__title {
    color: rgb(36, 36, 36);
    margin: 0px;
    text-transform: capitalize;
    font-weight: 500;
}
.jMrZxT .block-header__usage {
    color: rgb(120, 120, 120);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.jMrZxT .block-header__usage .info-icon {
    margin-left: 4px;
    cursor: pointer;
}
.jMrZxT .coupon-list {
    margin-bottom: 16px;
    display: grid;
    gap: 12px;
}
.jMrZxT .show-more {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(11, 116, 229);
    font-size: 13px;
    line-height: 20px;
    cursor: pointer;
}
.jMrZxT .show-more .coupon-icon {
    margin-right: 8px;
}
/* /// */
.bJqXBw {
    padding: 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 3px;
    margin-top: 15px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
}
.bJqXBw .asa-freeship-icon {
    flex-shrink: 0;
    margin-right: 12px;
    opacity: 0.5;
}


.bJqXBw .asa-freeship-content {
    flex: 1 1 0%;
    margin-right: 16px;
    opacity: 0.5;
}
.bJqXBw .asa-freeship-content__title {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 2px;
    -webkit-text-fill-color: transparent;
    color: rgb(128, 128, 137);
}
.bJqXBw .asa-freeship-content__sub-title {
    font-size: 12px;
    line-height: 16px;
    color: rgb(128, 128, 137);
    display: block;
}

.bJqXBw .asa-freeship-switch {
    flex-shrink: 0;
}
.IvzMw {
    position: relative;
    display: flex;
    align-items: flex-start;
}
.IvzMw input[type="checkbox"] {
    opacity: 0;
    width: 0px;
    height: 0px;
}
.IvzMw input[type="checkbox"]:disabled + .slider {
    background-color: rgb(235, 235, 240);
}
.IvzMw .round {
    border-radius: 28px;
}
.IvzMw .slider {
    width: 48px;
    height: 24px;
    position: relative;
    cursor: pointer;
    margin-right: 12px;
    z-index: 1;
    background-color: rgb(166, 166, 176);
    transition: all 0.4s ease 0s;
}
.IvzMw .round::before {
    border-radius: 50%;
}
.IvzMw .slider::before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: all 0.4s ease 0s;
}
/* ///////////////// */

.cKJPhS.cKJPhS {
    border-radius: 4px;
    margin-bottom: 12px;
    padding: 0px;
}

.cKJPhS .block-header {
    font-size: 14px;
    line-height: 20px;
    padding: 16px;
    border-bottom: 1px solid rgb(235, 235, 240);
}

.cKJPhS .block-header__title {
    font-weight: 500;
    color: rgb(56, 56, 61);
    margin: 0px;
}

.cKJPhS .block-header__sub-title {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.cKJPhS .block-header__sub-title .sub-title-text {
    color: rgb(128, 128, 137);
    font-weight: 400;
    margin: 0px 4px 0px 0px;
}
.cKJPhS .block-header__sub-title .sub-title-link {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    color: rgb(11, 116, 229);
    font-weight: 400;
    margin: 0px;
    cursor: pointer;
}
.cKJPhS .block-header__sub-title .sub-title-link__arrow {
    transform: rotate(90deg);
    transition-duration: 0.5s;
}
.cIdCpS {
    display: block;
    transition: max-height 0.5s ease-in-out 0s;
    overflow: hidden;
    max-height: 0px;
}
.cIdCpS .list-container {
    border-bottom: 1px solid rgb(235, 235, 240);
    padding: 12px 16px;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 12px;
    display: grid;
    gap: 4px;
}

.cIdCpS .item-info {
    margin-right: 8px;
    display: flex;
    align-items: flex-start;
}
.cIdCpS .item-info__qty {
    width: 30px;
    margin-right: 8px;
    font-weight: 500;
    flex-shrink: 0;
}
.cIdCpS .item-info__name {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
    max-width: 156px;
    font-weight: 400;
}
.cIdCpS .item-price {
    font-weight: 500;
    flex-shrink: 0;
}

.eWlcNY {
    padding: 8px 16px;
    display: grid;
    gap: 4px;
    font-size: 14px;
    line-height: 20px;
}
.bSkntM {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.eWlcNY .summary-label {
    color: rgb(128, 128, 137);
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.eWlcNY .summary-value {
    color: rgb(56, 56, 61);
}

.jFlDlb {
    width: 100%;
    height: 1px;
    background-color: rgb(235, 235, 240);
    margin: 0px auto;
}
.cKJPhS .order-total {
    padding: 8px 16px;
}
.cKJPhS .order-total__label {
    font-size: 14px;
    line-height: 20px;
    color: rgb(56, 56, 61);
}
.cKJPhS .order-total__value {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.cKJPhS .order-total__total {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: rgb(255, 66, 78);
}
.cKJPhS .order-total__additional-text {
    font-size: 12px;
    line-height: 16px;
    color: rgb(128, 128, 137);
}

.hHWBHK.hHWBHK {
    margin: 0px 16px 16px;
    color: rgb(255, 255, 255);
    background-color: rgb(255, 66, 78);
    border: none;
}
.OMovB {
    width: 100%;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 44px;
    background-color: rgb(255, 255, 255);
    outline: 0px;
    cursor: pointer;
    border: 1px solid rgb(11, 116, 229);
    color: rgb(11, 116, 229);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
}

