.des{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
.des span{
    font-size: 17px;
    font-weight: 400;
    color: rgb(105, 105, 105);  
}
.ContentPoint{
    background: #fff;
    padding: 15px 20px;
    border-radius: 20px;
}
.point{
    color: #1890ff;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
    padding-bottom: 8px;
    border-bottom: 3px solid #1890ff;
}