.voucher{
    display: flex;
    justify-content: space-between;
    background: #fff;
    padding: 15px;
}
.item{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
}
.item img{
    width: 48px;
    height: 48px;
}
.item h5{
    margin-top: 12px;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.54;
    color: rgb(36, 36, 36);
    width: 81px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: center;
    margin-bottom: 0;
    padding-bottom: 0;
}
.banner{
    display: none;visibility: hidden;
}
@media screen and (max-width: 768px){
    .voucher{
        justify-content: start;
        overflow-x: auto;
        max-width: 100vw;
        padding: 0 4px;
        padding-top: 50px;
        padding-bottom: 5px;
        border-radius: 15px 15px 0 0;
        border-bottom: 6px solid #d6d6d6;
    }
    .voucher::-webkit-scrollbar{
        display: none;
    }
    .item{
        flex-shrink: 0;
        width: 22vw;
    }
    .item img{
        height: 35px;
        width: 35px;
    }
    .item h5{
        margin: 0;
        padding: 0;
        margin-top: 10px;
        font-size: 12px;
    }
    .banner{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        visibility: visible;
        position: absolute;
        z-index: 10;
        top: -80px;
        width: 100%;
        gap: 5px;
    }
    .banner img{
        border-radius: 6px;
    }
    .banner img:first-child,
    .banner img:nth-child(3)
    {
        width: 28vw;
        height: calc(28vw/1.3);
        object-fit: cover;
    }
    .banner img:nth-child(2){
        width: 33vw;
        height:calc(33vw/1.2);
        object-fit: cover;
    }
    .banner_voucher{
        position: relative;
        margin-top: 80px;
        width: 100vw;
    }
}