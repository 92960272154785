*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.shopDashboardContainer{
    display: flex;
    justify-content: space-between;
    
    margin: auto;
    margin-top: 16px;
    width: 100%;
    
}
.shopDashBoardAll{
    width: 1000px;
    margin: auto;
}
@media screen and (max-width:768px){
    .shopDashBoardAll{
        max-width: 100%;
        padding: 10px;
    }
    .shopDashboardContainer{
        display: flex;
        flex-wrap: wrap;
    }
}