*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
   
}
body{
    height: fit-content;
}
.box{
    width: 100vw;
    /* height: calc(100vh - 70px);
    
    margin-top: 70px; */
    height: 100vh;
    position: relative;
  

}
.menu{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 16px;
    background: rgb(26, 148, 255);
    
    position: fixed;
   top: 0;
   right: 0;
   left: 0;
   width: 100%;
   height: 80px;
   z-index: 11;
   box-shadow: 0 5px 10px rgba(0,0,0,0.1);
   
}
.menu a{
    text-decoration: none;
    color: black;
    padding: 8px 8px;
    display: inline-block;
    
    letter-spacing: 5px;
    text-align: center;
    font-size: 12px;
}
.menu  h2{
    color: black;
    margin-left: 20px;
    display: inline-block;
  
    letter-spacing: 5px;
    text-align: center;
    font-size: 30px;
}

.menu-left a{

    margin: 0 8px 0 0;
}
.menu-left img{
    width: 25%;
    
}
.menu-left h4{
 margin-left: 20px;
}
.menu-right a{
    color: white;
}

.box_background{
    background-image: url("https://salt.tikicdn.com/ts/brickv2og/62/f0/86/2c7ed18017c882a98946117913e1e5d1.png");
    background-size: cover;
    position: absolute ;
    width: 100%;
    height: 100%;
    inset: 0;
    background-repeat: no-repeat;
}   
.container {
    width: 400px;
    position: absolute;
    transform: translateY(50%);
    bottom: 45%;
    right: 120px;
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 5px 5px 50px rgba(0,0,0,0.5);
}
.title {
    text-align: center;
}
.ant-space{
    display: inline;
}
.ant-input-affix-wrapper{
    padding: 0 16px;
    border-radius:5px ;
}
.inputGroup input, .ant-input {
    width: 100% !important;
    padding: 4px 16px;
    border-radius: 5px;
    height: 28px;
    outline: none;
}
.inputGroup div{
    margin: 10px 0;
}
.form-control {
	border: 1px solid #eee;
	box-shadow: none;
}

.form-control:focus {
	border: 1px solid #30a5ff;
	outline: 0;
	box-shadow: inset 0px 0px 0px 1px #30a5ff;
}
.textGroup{
    text-align: right;
    
}
.textGroup{
    display: flex;
    justify-content: space-between;
}
.textGroup p:first-child{
    
margin-bottom: -10px;
    
}
.textGroup_1{
    text-align: left;
}
.textGroup_1 p{
 margin: 5px 0;

}
.textGroup_1  a{
    text-decoration: none;
    color: #007dff;
}

.textGroup p{
    margin: 10px 0;
   
}
.textGroup a{
   
    text-decoration: none;
    color: #007dff;

}
.buttonGroup button{
    width: 100%;
   padding: 5px 24px;
    font-size: 18px;
  
    color: white;
    border: none;

}
.buttonGroup h3{
    text-align: left;
    font-size: 15px;
}
.login {
    background-color: #007dff;
    border-radius: 10px;
}
.login:hover{
    background-color: #007bffbb;
}


.buttonGroup a{
    width: 100%;
     font-size: 30px;
     text-align: right;
     
}
.user-createShop-select{
    border-radius: 8px;
    height: auto;
    width: auto;
    padding: 6px 12px;
    border: none;
    
}
.user-createShop-select:focus {
	border: 1px solid #30a5ff;
	outline: 0;
	box-shadow: inset 0px 0px 0px 1px #30a5ff;
}


   .ant-select-selection-item{
    font-size: 1rem;
    letter-spacing: 0;
    
   }

   .ant-select-selector  {
    border-radius: 20px !important;
   }

   .buttonGroup1 button{
    width: 100%;
   padding: 5px 24px;
    font-size: 15px;
  
    border-radius: 5px;
    border: none;
    margin-top: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    

}
.line{
    position: relative;
    height: 1px;
    width: 100%;
    margin: 13px 0;
    background-color: #d4d4d4;
}
.line::before{
    content: 'Or';
    position: absolute;
    top: 50%;
    left: 50%;     
    transform: translate(-50%, -50%);
  background-color: white;
    color: #8b8b8b;
    padding: 0 5px;
    /* margin: 2px; */
    /* opacity: 0.5; */
}

button.facebook{
    color: #fff;
    background-color: #4267b2;
}
button.facebook .iconface{
    height: 28px;
    width: 28px;
    color: #0171d3;
    font-size: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
}

.iconface,
img.google-img{
    position: absolute;
   
   left: 50px;

}

img.google-img{
    border-radius: 20px;
    height: 30px;
    width: 30px;
    object-fit: cover;
}
a.google{
    border: 1px solid #CACACA;
}
a.google button{
    font-weight: 500;
    opacity: 0.6;
    color: #232836;
}
.warning{
    font-size: 0.8rem;
  
    color: #f00e0e;
}


   @media screen and (min-width: 10px) and (max-width: 800px){
    .container {
        width: 80%;
        position: relative;
        top: 50%;
        left: 50%;
      
        transform: translate(-50%,-50%);
        background-color: white;
        border-radius: 8px;
        padding: 30px;
        box-shadow: 5px 5px 50px rgb(0 0 0 / 50%);
        
    }
    
    .box_background{
      background-position: center;
        
    }  
    .buttonGroup1{
        background-position: center;
    }
    .title h1{
        font-size: 25px;
    }
   }

   .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
  }