.Shoplist {
    width: 610px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}
.Shop_ProfileLeft {
    display: flex;
}
.ListShopProfile {
    display: flex;
    justify-content: space-between;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    box-shadow:  0px 0px 2px rgb(0 0 0 / 10%);
    border-bottom: 1px solid #ccc ;
    border-radius: 4px 4px 0 0;
    
}
.Shop_ProfileLeft img {
    width: 55px;
   
}
.Shop_ProfileLeft h4 {
    color: rgb(89, 91, 211);
    font-weight: 480;
    font-size: 16px;
    margin-left: 10px;
}

.ShowShopdata {
    width: 100%;
    margin-bottom: 30px;

}
.SetShopOptions {
    display: flex;
    background-color: #e6e3eee0;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 2px;
    border-bottom: 1px solid #ccc;
    border-radius: 0 4px 4px 0;
}
.ShopIDtext {
    color: red;
    font-size: 15px;
    margin: 5px 5px 0 0;
} 
.Options button {
    border: none;
    padding: 3px;
    font-weight: 450;
    background-color: white;
    color: black;
    margin-right: 9px;
}
.Options button:hover {
    cursor: pointer;
}
.Time_ShopStart span {
    font-weight: bold;
    margin-right: 5px;
}
@media screen and (max-width: 768px){
    .Shop_ProfileLeft img {
        width: 40px;
    }
    .Shop_ProfileLeft h4 {
        font-size: 15px;
    }
    .ShopIDtext span{
        font-size: 14px;
    }
    .Time_ShopStart span{ 
        font-size: 16px;
    }
}