*{
    margin:  0;
    padding: 0;
    box-sizing: border-box;
}
.HeaderTopLeft {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex: 1 1 0%;
}
.HeaderBody {
    width: 1270px;
    margin: auto;
    margin-left: auto;
    margin-right: auto;
}
.Header {
    background-color: rgb(26, 148, 255);
   
   
}
.HeaderTopContainer {
    padding-top: 16px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    height: auto;
}
.HeaderTopContainerLogo img {
    font-size: 14px;
    height: 40px;
}
.HeaderTopContainerRight {
    display: flex;
    align-items: center;
    margin-left: 6px;
    justify-content: center;
    
}
.componentHeaderInput {
    width: 680px;
    padding: 0 12px;
    height: 44px;
    flex: 1 1 0%;
    border-radius: 3px 0 0 3px;
    outline: none;
    border: 0px;
}
.componentHeaderInput:focus + .ShowCoverInput{
    display: block;
}
.componentHeaderInput:focus + .HeaderMiddleListData {
    display: none;
}

.HeaderbuttonSeach {
    background-color: rgb(13, 92, 182);
    width: 120px;
    border-radius: 0px 3px 3px 0;
    display: flex;
    padding: 1px 6px;
    align-items: center;
    color: white;
    height: 44px;
    border: none;
    outline: none;
}
.icon-seach {
    font-weight: 500;
    margin-right: 8px;
    width: 20px;
    height: 20px;
}
.HeaderTopContainerInput {
    display: flex;
    justify-content: center;
    right: 35px;
    position: absolute;

}
.HeaderUserImg {
   width: 32px;
   height: 32px;
   margin-right: 6px;
}
.HeaderTopContainerUser {
    display: flex;
    color: white;
}
.HeaderUserLoginContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start
}
.HeaderUserLoginUser {
    font-size: 13px;
   
}
.HeaderUserLogin{
    font-size: 11px;
}
.HeaderUserImgdown {
    width: 16px;
    height: 16px;
}
.HeaderCart {
    width: 32px;
    height: 32px;
    
}
.HeaderTopContainerCartWallet {
    position: relative;
    display: flex;
    margin-left: 16px;
}
.HeaderCartTitle {
    position: absolute;
    padding: 0 7px;
    height: 20px;
    top: -2px;
  right: -6px;
  background-color: rgb(253, 216, 53);
  border-radius: 50%;
}
.HeaderWalletCart {
    position: relative;
}
.HeaderCartText{
    font-size: 12px;
    display: flex;
    color: white;
    align-items: flex-end;
}
.HeaderMiddle {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.HeaderMiddleListData a {
    color: white;
    
    text-decoration: none;
    margin-right: 12px;
    font-size: 13px;
}
.HeaderMiddleListData {
     position: absolute;
    left: 190px;
}
.HeaderSellerText {
    color: white;
    font-size: 14px;
    text-decoration: none;
    line-height: 16px;
}
.HeaderSeller {
    padding: 0 8px;
    margin-bottom: 12px;
    height: 22px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.25);
    display: flex;
    text-decoration: none;
    align-items: center;
}
.HeaderSeller img {
    margin-right: 4px;
    width: 12px;
    height: 12px;
}

.cursorPoiter {
    cursor: pointer;
}
.HeaderMobie{
    width: 100%;
}
.HeaderMobieTop {
    display: flex;
    padding: 0 15px;
    position: relative;
    justify-content: space-between;
    align-items: center;
}
.HeaderMobieMidle {
    padding: 10px;
  
}
.HeaderMobieText {
    width: 98%;
    height: 24px;
    outline: none;
    border-radius: 3px;
    border: none;
   
}
.HeaderMobieBottom {
 overflow-x: auto;
 display: flex;
}
.HeaderMobieBottom a {
    text-decoration: none;
    color: white;
    min-width: 20%;
    font-size: 12px;
}
.HeaderMobieLogo {
    position: absolute;
    left: 50%;
}
.InputCoverItemTop {
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.ShowCoverInput {
   border-top:  1px solid rgb(225, 225, 225);
    box-shadow: rgb(0 0 0 / 28%) 0px 6px 12px 0px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    z-index: 1;
    width: calc(100% - 120px);
    display: none;
}
.InputCoverItemTop p {
 margin-left: 10px;
}
.HistorySeach {
  border-bottom:  1px solid rgb(225, 225, 225);
   
}
.HeaderCoverInput {
    display: flex;
    text-decoration: none   ;
    justify-content: space-between;
    height: 35px;
    align-items: center;
    padding: 0 10px;
}
.HeaderCoverInputText {
    text-decoration: none;
    font-size: 15px;
    color: black;
    font-weight: 500;
   
}
.ListHistorySeach {
    display: flex;

    font-size: 13px;
    font-weight: 500;
    align-items: center;
}
.ListHistorySeach:hover {
    background-color: rgba(27, 168, 255, 0.1);
}
.ListHistorySeach img {
    margin-right: 12px;
}
.ShowCoverInputListItem,.CatogoryAttenion {
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(255, 255, 255);
    justify-content: space-evenly;
    border-bottom: 1px solid rgb(242, 242, 242);
    padding: 12px 8px;
   

}
.InputCoverLists {
    width: 32%;
    display: flex;
    text-decoration: none;
    align-items: center;
    margin-top: 10px;
   
    background-color: rgb(250, 250, 250);
}
.InputCoverLists p {
    text-decoration: none;
    color: black;
    font-size: 13px;
}
.InputCoverLists:hover {
    box-shadow: rgb(0 0 0 / 10%) 0px 1px 2px 0px;
   
    
}
.CatogoryList {
    width: 24%;
    text-decoration: none;
    
}
.CatogoryList:hover {
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 20px;
    
}
.CatogoryList p {
    text-decoration: none;
    color: black;
    font-size: 13px;
}
@media  only screen and (min-width:769px){
    .HeaderMobie {
        display: none;
    }
}
@media only screen and (max-width: 768px){
    .HeaderBody{
        display: none;
    }
  }

@media only screen and (max-width: 600px){
    .HeaderMobieBottom {
        width: 100vw;
    }
}