.BannerShop{
    height: 150px;
    width: 100%;
    background-image: url(../media/tiki-banner.jpg);
    background-size: cover;
    background-position: center;
    position: relative;
    color: #fff;
    padding: 20px 25px 10px 20px;
}
.header img:first-child{
    width: 65px;
    height: 65px;
    border-radius: 50%;
    object-fit: cover;
}
.info{
    display: flex;
    margin-left: 20px;
    align-items: center;
}
.header{
    display: flex;
    align-items: center;
}
.info img{
    height: 18px;
}
.info h3{
    color: #fff;
    margin: 0;
    padding: 0; 
    line-height: 1;
    font-size: 17px;
}
.info button{
    padding: 6px 12px;
    background-color: rgb(11, 116, 229);
    border: 1px solid rgb(11, 116, 229);
    border-radius: 4px;
    color: rgb(255, 255, 255);
    font-size: 13px;
    font-weight: 500;
    line-height: 1.54;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    cursor: pointer;
    height: 35px;
    margin-left: 50px;
    position: relative;
}
.info button::after{
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: rgba(255, 255, 255,0.6);
    left: -25px;
}
.footer{
    position: relative;
    top: -65px;
}
.search{
    position: absolute;
    right: 30px;
    z-index: 2;
    bottom: 15px;
}
@media screen and (max-width: 768px){
    .BannerShop{
        width: 100vw;
        height: 150px;
        position: relative;
        padding: 5px 20px;
    }
    .search{
        bottom: 50px;
        width: 90vw;
        position: absolute;
        left: 5vw;
    }
    .info button::after{
        left: -35px;
    }
    .info button{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .follower{
        display: none;
        visibility: hidden;
    }
    .header .avatar{
        width: 50px !important;
        height: 50px !important;
    }
}