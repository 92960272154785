.product-bottom {
  margin-bottom: 16px;
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
}

h2 {
  color: rgb(51, 51, 51);
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  padding: 8px 16px;
  text-transform: capitalize;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin: 0px;
}
.content {
  width: 920px;
  padding: 0px 16px 16px;
  display: inline-block;
  color: rgb(36, 36, 36);
  line-height: 21px;
  text-align: justify;
  border-radius: 4px;
}
.content table {
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
  width: 100% !important;
}
tbody {
  display: table-row-group;
  vertical-align: middle;
  border-color: inherit;
}
tr {
  font-size: 13px;
  border-bottom: 0px;
}
tr:nth-child(2n) {
  background-color: rgb(250, 250, 250);
}
td {
  padding: 10px 15px;
}
td:first-child {
  width: 220px;
  vertical-align: top;
  color: rgb(79, 79, 79);
  font-size: 13px;
  font-weight: 500;
  background: rgb(239, 239, 239);
}
td:last-child {
  flex: 1 1 0%;
  border-right: 0px;
}
.p {
  margin-top: 5px;
  margin-bottom: 12px;
}
